<template>
  <v-container>
    <v-row class="mb-6">
      <!-- page header -->
      <v-col cols="12">
        <v-row>
          <v-col cols="10"
            ><h1 class="display-1 font-weight-medium">
              Hotels.com Scrape Listing
            </h1>
            
          </v-col>
        </v-row>
      </v-col>
      </v-row>
      <v-card outlined class="d-md-flex flex-md-column" :loading="loading">
          <v-card-text class="text--primary">
            <v-form ref="form" @submit.prevent="submit">
              <v-row>
                 <v-col cols="6">
                    <v-autocomplete
                        :loading="loading"
                        :disabled="loading"
                        :items="hotels"
                        item-text="resort"
                        v-model.trim="items.index"
                        item-value="index"
                        label="Select Hotel"
                        :rules="[(v) => !!v || 'Field Required']"
                      >
                      </v-autocomplete>
                 </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                        :loading="loading"
                        :disabled="loading"
                        :items="months"
                        item-text="month"
                        item-value="index"
                        v-model.trim="items.months"
                        label="Select Months"
                        :rules="[(v) => v.length ? true : 'Field Required']"
                        multiple
                      >
                      </v-autocomplete>
                 </v-col>
                 <v-col cols="6">
                    <v-autocomplete
                        :loading="loading"
                        :disabled="loading"
                        :items="days"
                        v-model.trim="items.days"
                        item-text="day"
                        item-value="index"
                        label="Select Days"
                        :rules="[(v) => v.length ? true : 'Field Required']"
                        multiple
                      >
                      </v-autocomplete>
                 </v-col>
                 <v-col cols="6">
                    <v-text-field
                        :loading="loading"
                        :disabled="loading"
                        v-model.trim="items.numberOfNight"
                        label="Number of Nights"
                        type="number"
                        :rules="[(v) => !!v || 'Field Required']"
                      >
                      </v-text-field>
                 </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                        :loading="loading"
                        :disabled="loading"
                        :items="years"
                        item-text="year"
                        @input="yearSelected"
                        v-model.trim="items.year"
                        label="Select Year"
                        :rules="[(v) => !!v || 'Field Required']"
                      >
                      </v-autocomplete>
                 </v-col>
                 <v-col cols="12">
                    <v-btn
                    class="float-right"
                        color="blue "
                        @click="submit"
                        :loading="loading"
                        :disabled="loading"
                        >Submit</v-btn
                    >
                 </v-col>
              </v-row>  
            </v-form></v-card-text></v-card>
    </v-container>
</template>

<script>
import { mainApp, portalsApp } from "../../firebase";
import env from '../../../../env';
import axios from 'axios'
export default {
  
  data: () => ({
    loading:false,
    items:{},
    years: [],
    days:[
  { day: 'Monday' , index: 0},
  { day: 'Tuesday', index: 1 },
  { day: 'Wednesday' , index: 2},
  { day: 'Thursday' , index: 3},
  { day: 'Friday', index: 4 },
  { day: 'Saturday', index: 5 },
  { day: 'Sunday' , index: 6}
],
    months:[
  { month: 'January', index: 1 },
  { month: 'February', index: 2 },
  { month: 'March', index: 3 },
  { month: 'April', index: 4 },
  { month: 'May', index: 5 },
  { month: 'June', index: 6 },
  { month: 'July', index: 7 },
  { month: 'August', index: 8 },
  { month: 'September', index: 9 },
  { month: 'October', index: 10 },
  { month: 'November', index: 11 },
  { month: 'December', index: 12 }
],
    hotels:[
  {
    resortId: 'HBSV',
    HotelsId: 'ho456343',
    Destination: 'Bali, Indonesia',
    resort: 'Bayshore Villas',
    numberOfNight: 7,
    resortAddress: 'Candi Dasa Street, Manggis, Bali, 80871',
    unitToBeScrabe: 'Apartment, 1 Bedroom, Private Pool  -AND- Apartment, 2 Bedrooms, Private Pool',
    website: 'https://www.hotels.com/ho456343/bayshore-villas-candi-dasa-manggis-indonesia/',
    index: 1
  },
  {
    resortId: 'SPGH',
    HotelsId: 'ho199172',
    Destination: 'Sun Peaks, BC, Canada',
    resort: 'Sun Peaks Grand Hotel and Conference Centre',
    numberOfNight: 4,
    resortAddress: '3240 Village Way, Sun Peaks, BC, V0E 5N0',
    unitToBeScrabe: 'Deluxe Room, 1 King Bed, Non Smoking',
    website: 'https://www.hotels.com/ho199172/sun-peaks-grand-hotel-conference-centre-sun-peaks-canada/',
    index: 2
  },
  {
    resortId: 'DSBA',
    HotelsId: 'ho395514',
    Destination: 'Buenos Aires, Argentina',
    resort: 'Design Suites Buenos Aires',
    numberOfNight: 7,
    resortAddress: 'Tacuari 243, San Telmo, Buenos Aires, Capital Federal, 1071',
    unitToBeScrabe: 'Junior Studio Suite',
    website: 'https://www.hotels.com/ho395514/design-suites-buenos-aires-buenos-aires-argentina/',
    index: 3
  },
  {
    resortId: 'TWCC',
    HotelsId: 'ho314678',
    Destination: 'Cape Coral, Florida',
    resort: 'The Westin Cape Coral Resort at Marina Village',
    numberOfNight: 5,
    resortAddress: '5951 Silver King Blvd., Cape Coral, FL, 33914',
    unitToBeScrabe: 'Deluxe Room, 1 King Bed, Balcony, Marina View -AND- Suite, 1 Double Bed, Balcony, River View',
    website: 'https://www.hotels.com/ho314678/the-westin-cape-coral-resort-at-marina-village-cape-coral-united-states-of-america/',
    index: 4
  },
  {
    resortId: 'ECGV',
    HotelsId: 'ho312204',
    Destination: 'County Clare, Ireland',
    resort: 'East Clare Golf Village',
    numberOfNight: 7,
    resortAddress: 'Coolreagh House, Bodyke, County Clare, V94 RWH9',
    unitToBeScrabe: 'Superior Cottage, Ensuite -AND- Family House, Private Bathroom',
    website: 'https://www.hotels.com/ho312204/east-clare-golf-village-bodyke-ireland/',
    index: 5
  },
  {
    resortId: 'TJOE',
    HotelsId: 'ho197729',
    Destination: 'Enfield, Co. Meath, Ireland',
    resort: 'Johnstown Estate',
    numberOfNight: 5,
    resortAddress: 'Johnstown Estate, Enfield, Co. Meath',
    unitToBeScrabe: 'Executive King room',
    website: 'https://www.hotels.com/ho197729/the-johnstown-estate-enfield-ireland/',
    index: 6
  },
  {
    resortId: 'TAHS',
    HotelsId: 'ho242771',
    Destination: 'Fort Lauderdale, Florida',
    resort: 'The Atlantic Hotel and Spa',
    numberOfNight: 5,
    resortAddress: '601 N Fort Lauderdale Beach Blvd, Fort Lauderdale, FL, 33304',
    unitToBeScrabe: 'Studio, 1 King Bed, Ocean View -AND- Suite, 1 King Bed with Sofa bed, City View -AND- Studio, 1 King Bed, Oceanfront',
    website: 'https://www.hotels.com/ho242771/the-atlantic-hotel-spa-fort-lauderdale-united-states-of-america/',
    index: 7
  },
  {
    resortId: 'HCOS',
    HotelsId: 'ho364984',
    Destination: 'Las Vegas, Nevada',
    resort: 'Cosmopolitan of Las Vegas',
    numberOfNight: 3,
    resortAddress: '3708 Las Vegas Blvd S, Las Vegas, NV, 89109',
    unitToBeScrabe: 'Studio, Terrace',
    website: 'https://www.hotels.com/ho364984/the-cosmopolitan-of-las-vegas-las-vegas-united-states-of-america/',
    index: 8
  },
  {
    resortId: 'HCPL',
    HotelsId: 'ho124363',
    Destination: 'Las Vegas, Nevada',
    resort: "Caesar's Palace",
    numberOfNight: 3,
    resortAddress: '3570 Las Vegas Boulevard South, Las Vegas, NV, 89109',
    unitToBeScrabe: 'Julius Deluxe Room, 1 King Bed, Non Smoking -AND- Forum Classic Room, 1 King Bed, Non Smoking',
    website: 'https://www.hotels.com/ho124363/caesars-palace-resort-casino-las-vegas-united-states-of-america/',
    index: 9
  },
  {
    resortId: 'HMGM',
    HotelsId: 'ho108540',
    Destination: 'Las Vegas, Nevada',
    resort: 'MGM Grand',
    numberOfNight: 3,
    resortAddress: '3799 Las Vegas Blvd S, Las Vegas, NV, 89109',
    unitToBeScrabe: 'Studio King -AND- Stay Well Grand King',
    website: 'https://www.hotels.com/ho108540/mgm-grand-hotel-casino-las-vegas-united-states-of-america/',
    index: 10
  },
  {
    resortId: 'HMLV',
    HotelsId: 'ho149069',
    Destination: 'Las Vegas, Nevada',
    resort: 'Mirage',
    numberOfNight: 3,
    resortAddress: '3400 Las Vegas Blvd S, Las Vegas, NV, 89109',
    unitToBeScrabe: 'Resort King -AND- King Strip View Room -AND- King Volcano View Room -AND- Resort Tower King',
    website: 'https://www.hotels.com/ho149069/the-mirage-hotel-casino-las-vegas-united-states-of-america/',
    index: 11
  },
  {
    resortId: 'HPLV',
    HotelsId: 'ho161293',
    Destination: 'Las Vegas, Nevada',
    resort: 'Paris Las Vegas',
    numberOfNight: 3,
    resortAddress: '3655 Las Vegas Blvd S, Las Vegas, NV, 89109',
    unitToBeScrabe: 'Burgundy Room, 1 King Bed, Non-Smoking -AND- Burgundy Room, 1 King Bed, Non-Smoking, End Hallway -AND- Burgundy Room, 1 King Bed, Non-Smoking, Pool View -AND- Burgundy Room, 1 King Bed, Non-Smoking, Eiffel View',
    website: 'https://www.hotels.com/ho161293/paris-las-vegas-resort-casino-las-vegas-united-states-of-america/',
    index: 12
  },
  {
    resortId: 'MBLV',
    HotelsId: 'ho163446',
    Destination: 'Las Vegas, Nevada',
    resort: 'Mandalay Bay',
    numberOfNight: 3,
    resortAddress: '3950 Las Vegas Blvd S, Las Vegas, NV, 89119',
    unitToBeScrabe: 'Resort King -AND- Stay Well Resort King -AND- Resort King Strip View',
    website: 'https://www.hotels.com/ho163446/mandalay-bay-resort-and-casino-las-vegas-united-states-of-america/',
    index: 13
  },
  {
    resortId: 'LHPL',
    HotelsId: 'ho113142',
    Destination: 'London, England',
    resort: 'London Hilton on Park Lane',
    numberOfNight: 4,
    resortAddress: '22 Park Lane, London, England, W1K 1BE',
    unitToBeScrabe: 'Deluxe Room, 1 King Bed -AND- Room, 1 King Bed -AND- Room, 1 Queen Bed ',
    website: 'https://www.hotels.com/ho113142/london-hilton-on-park-lane-london-united-kingdom/',
    index: 14
  },
  {
    resortId: 'CPMD',
    HotelsId: 'ho152413',
    Destination: 'Memphis, Tennessee',
    resort: 'Crowne Plaza Memphis Downtown Hotel',
    numberOfNight: 3,
    resortAddress: '300 N 2nd Street, Memphis, TN, 38105',
    unitToBeScrabe: 'Standard Room -AND- Standard Room, 1 King Bed (Mid Floor) -AND- Standard Room, 1 King Bed (High Floor)',
    website: 'https://www.hotels.com/ho152413/crowne-plaza-memphis-downtown-hotel-an-ihg-hotel-memphis-united-states-of-america/',
    index: 15
  },
  {
    resortId: 'GHGL',
    HotelsId: 'ho476955296',
    Destination: 'Memphis, Tennessee',
    resort: 'The Guest House at Graceland',
    numberOfNight: 3,
    resortAddress: '3600 Elvis Presley Blvd, Memphis, TN, 38116',
    unitToBeScrabe: 'Deluxe King Room -AND- Superior Room, 1 King Bed, Courtyard View',
    website: 'https://www.hotels.com/ho476955296/the-guest-house-at-graceland-memphis-united-states-of-america/',
    index: 16
  },
  {
    resortId: 'HCHH',
    HotelsId: 'ho198618',
    Destination: 'Memphis, Tennessee',
    resort: 'Hu. Hotel',
    numberOfNight: 5,
    resortAddress: '79 Madison Avenue, Memphis, TN, 38103',
    unitToBeScrabe: 'King Guestroom -AND- One Queen Guestroom -AND- Deluxe Room, 1 King Bed -AND- King Junior Suite -AND- Standard King - AND- Standard One Queen Bed',
    website: 'https://www.hotels.com/ho198618/hu-hotel-memphis-united-states-of-america/',
    index: 17
  },
  {
    resortId: 'HSMD',
    HotelsId: 'ho141231',
    Destination: 'Memphis, Tennessee',
    resort: 'Sheraton Memphis Downtown',
    numberOfNight: 3,
    resortAddress: '250 N Main St, Memphis, TN, 38103',
    unitToBeScrabe: 'Traditional Room, 1 King Bed, Non Smoking',
    website: 'https://www.hotels.com/ho141231/sheraton-memphis-downtown-hotel-memphis-united-states-of-america/',
    index: 18
  },
  {
    resortId: 'ERMB',
    HotelsId: 'ho114060',
    Destination: 'Miami Beach, Florida',
    resort: 'Eden Roc Miami Beach',
    numberOfNight: 5,
    resortAddress: '4525 Collins Ave, Miami Beach, FL, 33140',
    unitToBeScrabe: 'Premium Room, 1 King Bed (Bay View)',
    website: 'https://www.hotels.com/ho114060/eden-roc-miami-beach-miami-beach-united-states-of-america/',
    index: 19
  },
  {
    resortId: 'CHAH',
    HotelsId: 'ho116873',
    Destination: 'Nashville, Tennessee',
    resort: 'The Capitol Hotel Downtown',
    numberOfNight: 3,
    resortAddress: '711 Union Street, Nashville, TN, 37219',
    unitToBeScrabe: 'Standard Room, 1 King Bed, Non Smoking',
    website: 'https://www.hotels.com/ho116873/the-capitol-hotel-downtown-ascend-hotel-collection-nashville-united-states-of-america/',
    index: 20
  },
  {
    resortId: 'HCIO',
    HotelsId: 'ho175492',
    Destination: 'Nashville, Tennessee',
    resort: 'The Inn at Opryland',
    numberOfNight: 3,
    resortAddress: '2401 Music Valley Dr, Nashville, TN, 37214',
    unitToBeScrabe: 'Room, 1 King Bed, Non Smoking',
    website: 'https://www.hotels.com/ho175492/the-inn-at-opryland-a-gaylord-hotel-nashville-united-states-of-america/',
    index: 21
  },
  {
    resortId: 'HCRN',
    HotelsId: 'ho114044',
    Destination: 'Nashville, Tennessee',
    resort: 'Renaissance Nashville Hotel',
    numberOfNight: 3,
    resortAddress: '611 Commerce St, Nashville, TN, 37203',
    unitToBeScrabe: 'Room, 1 King Bed, Non Smoking',
    website: 'https://www.hotels.com/ho114044/renaissance-nashville-hotel-nashville-united-states-of-america/',
    index: 22
  },
  {
    resortId: 'HGOH',
    HotelsId: 'ho125186',
    Destination: 'Nashville, Tennessee',
    resort: 'Gaylord Opryland Resort',
    numberOfNight: 3,
    resortAddress: '2800 Opryland Drive, Nashville, TN, 37214',
    unitToBeScrabe: 'Room, 1 King Bed, Non Smoking, View (Exterior View)',
    website: 'https://www.hotels.com/ho125186/gaylord-opryland-resort-convention-center-nashville-united-states-of-america/',
    index: 23
  },
  {
    resortId: 'HHHN',
    HotelsId: 'ho652738432',
    Destination: 'Nashville, Tennessee',
    resort: 'Holston House Nashville',
    numberOfNight: 5,
    resortAddress: '118 7th Avenue North, Nashville, TN, 37203',
    unitToBeScrabe: 'Room, 1 King Bed',
    website: 'https://www.hotels.com/ho652738432/holston-house-nashville-in-the-unbound-collection-by-hyatt-nashville-united-states-of-america/',
    index: 24
  },
  {
    resortId: 'HMMH',
    HotelsId: 'ho112390',
    Destination: 'Nashville, Tennessee',
    resort: 'Millenium Maxwell House',
    numberOfNight: 5,
    resortAddress: '2025 Rosa L. Park Boulevard, Nashville, TN, 37228',
    unitToBeScrabe: 'Standard Room, 1 King Bed',
    website: 'https://www.hotels.com/ho112390/millennium-maxwell-house-nashville-nashville-united-states-of-america/',
    index: 25
  },
  {
    resortId: 'HCRS',
    HotelsId: 'ho115078',
    Destination: 'New Orleans, Louisiana',
    resort: 'Royal Sonesta New Orleans',
    numberOfNight: 3,
    resortAddress: '300 Bourbon St, New Orleans, LA, 70130',
    unitToBeScrabe: 'Deluxe Room, 1 King Bed, Non Smoking -AND- Room, 1 King Bed, Non Smoking, Balcony (Interior)',
    website: 'https://www.hotels.com/ho115078/royal-sonesta-new-orleans-new-orleans-united-states-of-america/',
    index: 26
  },
  {
    resortId: 'HCHE',
    HotelsId: 'ho1059989216',
    Destination: 'New York City, New York',
    resort: 'Hotel Hendricks',
    numberOfNight: 3,
    resortAddress: '25 W 38th Street, New York, NY, 10018',
    unitToBeScrabe: 'Empire State View King -AND- Deluxe Room, 1 King Bed -AND- Empire State View Queen',
    website: 'https://www.hotels.com/ho1059989216/hotel-hendricks-new-york-united-states-of-america/',
    index: 27
  },
  {
    resortId: 'HCHE2',
    HotelsId: 'ho1059989216',
    Destination: 'New York City, New York',
    resort: 'Hotel Hendricks',
    numberOfNight: 3,
    resortAddress: '25 W 38th Street, New York, NY, 10018',
    unitToBeScrabe: 'Empire State View King -AND- Deluxe Room, 1 King Bed -AND- Empire State View Queen',
    website: 'https://www.hotels.com/ho1059989216/hotel-hendricks-new-york-united-states-of-america/',
    index: 28
  },
  {
    resortId: 'HCMT',
    HotelsId: 'ho6295',
    Destination: 'New York City, New York',
    resort: 'The Manhattan at Times Square Hotel',
    numberOfNight: 3,
    resortAddress: '790 7th Ave, New York, NY, 10019',
    unitToBeScrabe: 'Standard Room -AND- Standard Room, 1 King Bed -AND- Standard King Room - High Floor',
    website: 'https://www.hotels.com/ho6295/the-manhattan-at-times-square-hotel-new-york-united-states-of-america/',
    index: 29
  },
  {
    resortId: 'HCMT2',
    HotelsId: 'ho6295',
    Destination: 'New York City, New York',
    resort: 'The Manhattan at Times Square Hotel',
    numberOfNight: 3,
    resortAddress: '790 7th Ave, New York, NY, 10019',
    unitToBeScrabe: 'Standard Room -AND- Standard Room, 1 King Bed -AND- Standard King Room - High Floor',
    website: 'https://www.hotels.com/ho6295/the-manhattan-at-times-square-hotel-new-york-united-states-of-america/',
    index: 30
  },
  {
    resortId: 'HCRH',
    HotelsId: 'ho134388',
    Destination: 'New York City, New York',
    resort: 'Row NYC',
    numberOfNight: 3,
    resortAddress: '700 8th Avenue, New York, NY, 10036',
    unitToBeScrabe: 'Standard Room -AND- Deluxe Room, 1 King Bed -AND- Superior Room',
    website: 'https://www.hotels.com/ho134388/row-nyc-new-york-united-states-of-america/',
    index: 31
  },
  {
    resortId: 'HCRH2',
    HotelsId: 'ho134388',
    Destination: 'New York City, New York',
    resort: 'Row NYC',
    numberOfNight: 3,
    resortAddress: '700 8th Avenue, New York, NY, 10036',
    unitToBeScrabe: 'Standard Room -AND- Deluxe Room, 1 King Bed -AND- Superior Room',
    website: 'https://www.hotels.com/ho134388/row-nyc-new-york-united-states-of-america/',
    index: 32
  },
  {
    resortId: 'HCRP',
    HotelsId: 'ho536247',
    Destination: 'New York City, New York',
    resort: 'Hotel Riu Plaza New York Times Square',
    numberOfNight: 3,
    resortAddress: '305 West, 46th Street, Manhattan, New York, NY, 10036',
    unitToBeScrabe: 'Junior Suite, 1 King Bed -AND- Executive Room, 1 King Bed -AND- Deluxe Room, 1 King Bed -AND- Deluxe Room, 1 Queen Bed',
    website: 'https://www.hotels.com/ho536247/hotel-riu-plaza-new-york-times-square-new-york-united-states-of-america/',
    index: 33
  },
  {
    resortId: 'HCRP2',
    HotelsId: 'ho536247',
    Destination: 'New York City, New York',
    resort: 'Hotel Riu Plaza New York Times Square',
    numberOfNight: 3,
    resortAddress: '305 West, 46th Street, Manhattan, New York, NY, 10036',
    unitToBeScrabe: 'Junior Suite, 1 King Bed -AND- Executive Room, 1 King Bed -AND- Deluxe Room, 1 King Bed -AND- Deluxe Room, 1 Queen Bed',
    website: 'https://www.hotels.com/ho536247/hotel-riu-plaza-new-york-times-square-new-york-united-states-of-america/',
    index: 34
  },
  {
    resortId: 'HCEM',
    HotelsId: 'ho626045',
    Destination: 'Panorama, BC, Canada',
    resort: 'Embarc Panorama',
    numberOfNight: 7,
    resortAddress: '2000 Panorama Drive, Panorama, BC, V0A 1T0',
    unitToBeScrabe: 'Standard Studio Suite, Kitchen -AND- Standard Suite, 1 Bedroom, Kitchen -AND- Standard Suite, 2 Bedrooms, Kitchen',
    website: 'https://www.hotels.com/ho626045/embarc-panorama-panorama-canada/',
    index: 35
  },
  {
    resortId: 'HCHT',
    HotelsId: 'ho402352',
    Destination: 'Panorama, BC, Canada',
    resort: 'Panorama Vacation Retreat at Horsethief Lodge',
    numberOfNight: 7,
    resortAddress: '2000 Panorama Drive (Bag 7000), Panorama, BC, V0A1T0',
    unitToBeScrabe: 'Studio -AND- Condo, 1 Bedroom -AND- Condo, 2 Bedrooms, 2 Bathrooms -AND- Condo, 3 Bedrooms, 2 Bathrooms',
    website: 'https://www.hotels.com/ho402352/panorama-vacation-retreat-at-horsethief-lodge-panorama-canada/',
    index: 36
  },
  {
    resortId: 'HCGT',
    HotelsId: 'ho139164',
    Destination: 'Paris, France',
    resort: 'Golden Tulip Hotel Washington Opera',
    numberOfNight: 5,
    resortAddress: '50 Rue De Richelieu, Paris, Paris, 75001',
    unitToBeScrabe: 'Classic Room, 1 Double Bed -AND- Junior Suite, 1 Double Bed -AND- Deluxe Room, 1 Double Bed',
    website: 'https://www.hotels.com/ho139164/golden-tulip-hotel-washington-opera-paris-france/',
    index: 37
  },
  {
    resortId: 'HCLE',
    HotelsId: 'ho253061',
    Destination: 'Paris, France',
    resort: "L'Empire Paris",
    numberOfNight: 5,
    resortAddress: '48 Rue De L Arbre Sec, Paris, Paris, 75001',
    unitToBeScrabe: 'Superior Room -AND- Deluxe Double Room',
    website: 'https://www.hotels.com/ho253061/l-empire-paris-paris-france/',
    index: 38
  },
  {
    resortId: 'HCPM',
    HotelsId: 'ho115012',
    Destination: 'Paris, France',
    resort: 'Paris Marriott Opera Ambassador',
    numberOfNight: 5,
    resortAddress: '16 Boulevard Haussmann, Paris, Paris, 75009',
    unitToBeScrabe: 'Comfort Room, 1 King Bed, Non Smoking -AND- Superior Room, 1 Queen Bed, Non Smoking -AND- Deluxe Room, 1 King Bed, Non Smoking, City View',
    website: 'https://www.hotels.com/ho115012/paris-marriott-opera-ambassador-paris-france/',
    index: 39
  },
  {
    resortId: 'HCGP',
    HotelsId: 'ho447952',
    Destination: 'Prague, Czech Republic',
    resort: 'Grandior Hotel Prague',
    numberOfNight: 5,
    resortAddress: 'Na Porici 42, Prague, 11000',
    unitToBeScrabe: 'Deluxe Suite -AND- Deluxe Suite -AND- Deluxe Double Room',
    website: 'https://www.hotels.com/ho447952/grandior-hotel-prague-prague-czech-republic/',
    index: 40
  },
  {
    resortId: 'HCHP',
    HotelsId: 'ho136900',
    Destination: 'Prague, Czech Republic',
    resort: 'Hilton Prague Old Town',
    numberOfNight: 5,
    resortAddress: 'V Celnici 7, Prague, 110 00',
    unitToBeScrabe: 'Room, 1 Queen Bed -AND- Room, 1 King Bed',
    website: 'https://www.hotels.com/ho136900/hilton-prague-old-town-prague-czech-republic/',
    index: 41
  },
  {
    resortId: 'HCMG',
    HotelsId: 'ho311812',
    Destination: 'Prague, Czech Republic',
    resort: 'Michelangelo Grand Hotel',
    numberOfNight: 5,
    resortAddress: 'Vladislavova 1477/20, Praha 1 - Nove Mesto, Prague, 110 00',
    unitToBeScrabe: 'Deluxe Double or Twin Room -AND- Suite',
    website: 'https://www.hotels.com/ho311812/michelangelo-grand-hotel-prague-prague-czech-republic/',
    index: 42
  },
  {
    resortId: 'HCEI',
    HotelsId: 'ho215193',
    Destination: 'Rome, Italy',
    resort: 'Exe International Palace',
    numberOfNight: 5,
    resortAddress: 'Via Nazionale 46, Rome, RM, 184',
    unitToBeScrabe: 'Economy Double Room -AND- Double Room',
    website: 'https://www.hotels.com/ho215193/exe-international-palace-rome-italy/',
    index: 43
  },
  {
    resortId: 'HCHM',
    HotelsId: 'ho420648',
    Destination: 'Rome, Italy',
    resort: 'Hotel Mancino 12',
    numberOfNight: 5,
    resortAddress: 'Via del Mancino 12, Rome, RM, 187',
    unitToBeScrabe: 'Superior Double Room',
    website: 'https://www.hotels.com/ho420648/hotel-mancino-12-rome-italy/',
    index: 44
  },
  {
    resortId: 'HCNH',
    HotelsId: 'ho172137',
    Destination: 'Rome, Italy',
    resort: 'Napoleon Hotel',
    numberOfNight: 5,
    resortAddress: 'Piazza Vittorio Emanuele II 105, Rome, RM, 185',
    unitToBeScrabe: 'Superior Double Room -AND- Single Room -AND- Standard Double Room',
    website: 'https://www.hotels.com/ho172137/napoleon-hotel-rome-italy/',
    index: 45
  },
  {
    resortId: 'HCAH',
    HotelsId: 'ho148307',
    Destination: 'Vienna, Austria',
    resort: 'Ambassador Hotel',
    numberOfNight: 5,
    resortAddress: 'Kaerntner Strasse 22, Vienna, Vienna, 1010',
 unitToBeScrabe: 'Double Room - Classic -AND- Twin/Double Room -AND- Twin/Double Room - Classic',
    website: 'https://www.hotels.com/ho148307/ambassador-hotel-vienna-austria/',
    index: 46
  },
  {
    resortId: 'HCGF',
    HotelsId: 'ho543528',
    Destination: 'Vienna, Austria',
    resort: 'Grand Ferdinand',
    numberOfNight: 5,
    resortAddress: 'Schubertring 10-12, Vienna, 1010',
    unitToBeScrabe: 'Standard Room -AND- Comfort Room -AND- Superior Room',
    website: 'https://www.hotels.com/ho543528/grand-ferdinand-vienna-your-hotel-in-the-city-center-vienna-austria/',
    index: 47
  },
  {
    resortId: 'HCVM',
    HotelsId: 'ho233409',
    Destination: 'Vienna, Austria',
    resort: 'Vienna Marriott Hotel',
    numberOfNight: 3,
    resortAddress: 'Parkring 12 A, Vienna, Vienna, 1010',
    unitToBeScrabe: 'Deluxe Room, 1 King Bed, Non Smoking',
    website: 'https://www.hotels.com/ho233409/vienna-marriott-hotel-vienna-austria/',
    index: 48
  },
   {
    resortId: "ho150387",
    resort: "Millennium Hotel Broadway Times Square",
    website: "https://www.hotels.com/ho150387/millennium-hotel-broadway-times-square-new-york-united-states-of-america/?chkin=2024-11-26&chkout=2024-11-29&x_pwa=1&rfrr=HSR&pwa_ts=1703171818720&referrerUrl=aHR0cHM6Ly93d3cuaG90ZWxzLmNvbS9Ib3RlbC1TZWFyY2g%3D&useRewards=false&rm1=a2&regionId=2621&destination=New+York%2C+New+York%2C+United+States+of+America&destType=MARKET&neighborhoodId=6157019&selected=25449&latLong=40.712843%2C-74.005966&hotelName=Millennium+Hotel+Broadway+Times+Square&sort=RECOMMENDED&top_dp=244&top_cur=USD&userIntent=&selectedRoomType=314220482&selectedRatePlan=382701853&expediaPropertyId=25449&searchId=650f6fce-ebfa-4132-ad54-8f3a9e4708b6",
    unitToBeScrabe: "Deluxe Room, Standard Room",
    index: 49
  },
  {
    resortId: "ho228901",
    index: 50,
    resort: "Oyster Bay Beach Resort",
    website: "https://www.hotels.com/ho228901/oyster-bay-beach-resort-oyster-pond-sint-maarten/?chkin=2024-11-26&chkout=2024-11-29&x_pwa=1&rfrr=HSR&pwa_ts=1703171929069&referrerUrl=aHR0cHM6Ly93d3cuaG90ZWxzLmNvbS9Ib3RlbC1TZWFyY2g%3D&useRewards=false&rm1=a2&regionId=553248635976384612&destination=Upper+Prince%27s+Quarter%2C+Philipsburg%2C+Sint+Maarten&destType=CURRENT_LOCATION&neighborhoodId=6131289&selected=1095348&latLong=18.05266%2C-63.01591&sort=RECOMMENDED&top_dp=194&top_cur=USD&userIntent=&selectedRoomType=200264142&selectedRatePlan=201554094&expediaPropertyId=1095348&searchId=290d75a6-b4a9-43e5-9379-76f2f490af7d",
    unitToBeScrabe: "Premeir Courtyard Room, Studio, Ocean Front Courtyard Room, Suite 1 Bedroom, Suite 2 Bedrooms"
  },
  {
    resortId: "ho208268",
    index: 51,
    resort: "Princess Heights Luxury Boutique Condo Hotel",
    website: "https://www.hotels.com/ho208268/princess-heights-luxury-boutique-condo-hotel-oyster-pond-sint-maarten/?chkin=2024-11-26&chkout=2024-11-29&x_pwa=1&rfrr=HSR&pwa_ts=1703172030236&referrerUrl=aHR0cHM6Ly93d3cuaG90ZWxzLmNvbS9Ib3RlbC1TZWFyY2g%3D&useRewards=false&rm1=a2&regionId=553248635976384612&destination=Upper+Prince%27s+Quarter%2C+Philipsburg%2C+Sint+Maarten&destType=CURRENT_LOCATION&neighborhoodId=6131289&selected=561365&latLong=18.04898%2C-63.01872&sort=RECOMMENDED&top_dp=172&top_cur=USD&userIntent=&selectedRoomType=314017081&selectedRatePlan=380366073&expediaPropertyId=561365&searchId=57ffd79d-fff0-4219-8b0c-24c16d36b48b",
    unitToBeScrabe: "Deluxe Suite 1 King Bed Kichenette Ocean View, Premium Suite Kitchenette Ocean View, Premium Suite 1 Bedroom Kitchen Ocean View, Premium Suite 2 Bedrooms Kitchen Ocean View"
  },
  {
    resortId: "ho457137",
    index: 52,
    resort: "Alda Hotel Reykjavik",
    website: "https://www.hotels.com/ho457137/alda-hotel-reykjavik-reykjavik-iceland/?chkin=2024-11-26&chkout=2024-11-29&x_pwa=1&rfrr=HSR&pwa_ts=1703172152477&referrerUrl=aHR0cHM6Ly93d3cuaG90ZWxzLmNvbS9Ib3RlbC1TZWFyY2g%3D&useRewards=false&rm1=a2&regionId=6054688&destination=Reykjavik%2C+Capital+Region%2C+Iceland&destType=CURRENT_LOCATION&neighborhoodId=553248635976007733&selected=8120033&latLong=64.14413%2C-21.92141&sort=RECOMMENDED&top_dp=150&top_cur=USD&userIntent=&selectedRoomType=316312400&selectedRatePlan=385815726&expediaPropertyId=8120033&searchId=c48eee9e-bd3f-47c1-8a7e-b3fbb7713661",
    unitToBeScrabe: "Double Room 1 King Bed, Double Room 1 Queen Bed, Double Room King Bed, Deluxe Room 1 King Bed, Deluxe Room 1 King Bed Balcony, Deluxe Double Room (with Sofabed), Double Room King Bed - De Luxe"
  },
  {
    resortId: "ho481895",
    index: 53,
    resort: "Black Pearl Luxury Apartments",
    website: "https://www.hotels.com/ho481895/black-pearl-luxury-apartments-reykjavik-iceland/?chkin=2024-11-26&chkout=2024-11-29&x_pwa=1&rfrr=HSR&pwa_ts=1703172293141&referrerUrl=aHR0cHM6Ly93d3cuaG90ZWxzLmNvbS9Ib3RlbC1TZWFyY2g%3D&useRewards=false&rm1=a2&regionId=6054688&destination=Reykjavik%2C+Capital+Region%2C+Iceland&destType=CURRENT_LOCATION&neighborhoodId=553248635976007733&selected=9484576&latLong=64.14968%2C-21.94243&sort=RECOMMENDED&top_dp=249&top_cur=USD&userIntent=&selectedRoomType=218444781&selectedRatePlan=276164921&expediaPropertyId=9484576&searchId=e71a0d86-2c68-41b1-bf47-276f76985a69",
    unitToBeScrabe: "Junior Suite Balcony, Standard Suite Balcony, Junior Suite, Suite "
  },
  {
    resortId: "ho259329",
    index: 54,
    resort: "Center Hotels Arnarhvoll",
    website: "https://www.hotels.com/ho259329/center-hotels-arnarhvoll-reykjavik-iceland/?chkin=2024-11-26&chkout=2024-11-29&x_pwa=1&rfrr=HSR&pwa_ts=1703172364116&referrerUrl=aHR0cHM6Ly93d3cuaG90ZWxzLmNvbS9Ib3RlbC1TZWFyY2g%3D&useRewards=false&rm1=a2&regionId=6054688&destination=Reykjavik%2C+Capital+Region%2C+Iceland&destType=CURRENT_LOCATION&neighborhoodId=553248635976007733&selected=1769638&latLong=64.149182%2C-21.930757&sort=RECOMMENDED&top_dp=194&top_cur=USD&userIntent=&selectedRoomType=194374&selectedRatePlan=385692068&expediaPropertyId=1769638&searchId=f41d3cb2-7536-4a57-afeb-73c72371b453",
    unitToBeScrabe: "Double Room Sea View, Standard Room or Twin Room, Standard Double Room, Deluxe Double or Twin Room, Deluxe Double Room, Twin Room Sea View, Deluxe Room Sea View, Deluxe Single Room"
  },
  {
    resortId: "ho163809",
    index: 55,
    resort: "Club Quarters Times Square Midtown",
    website: "https://www.hotels.com/ho163809/club-quarters-times-square-midtown-new-york-united-states-of-america/?chkin=2024-11-26&chkout=2024-11-29&x_pwa=1&rfrr=HSR&pwa_ts=1703172461005&referrerUrl=aHR0cHM6Ly93d3cuaG90ZWxzLmNvbS9Ib3RlbC1TZWFyY2g%3D&useRewards=false&rm1=a2&regionId=2621&destination=New+York%2C+New+York%2C+United+States+of+America&destType=CURRENT_LOCATION&neighborhoodId=129440&selected=533993&latLong=40.7561%2C-73.98119&sort=RECOMMENDED&top_dp=341&top_cur=USD&userIntent=&selectedRoomType=200257646&selectedRatePlan=201507004&expediaPropertyId=533993&searchId=6f30515e-4086-4259-9c76-342b197833da",
    unitToBeScrabe: "Standard Room, Club Room, Standard Room 1 King Bed, Superior Room, Suite"
  },
  {
    resortId: "ho591810",
    index: 56,
    resort: "Commodore Suites",
    website: "https://www.hotels.com/ho591810/commodore-suites-simpson-bay-sint-maarten/?chkin=2024-11-26&chkout=2024-11-29&x_pwa=1&rfrr=HSR&pwa_ts=1703172562848&referrerUrl=aHR0cHM6Ly93d3cuaG90ZWxzLmNvbS9Ib3RlbC1TZWFyY2g%3D&useRewards=false&rm1=a2&regionId=8941&destination=Simpson+Bay%2C+Sint+Maarten&destType=CURRENT_LOCATION&selected=15082011&latLong=18.03301%2C-63.09152&sort=RECOMMENDED&top_dp=117&top_cur=USD&userIntent=&selectedRoomType=201674701&selectedRatePlan=288219951&expediaPropertyId=15082011&searchId=3f95cf22-6be1-4019-b171-052bdca83251",
    unitToBeScrabe: "Deluxe Studio Suite 2 Double Beds Kitchenette, Luxury Suite 1 King Bed with Sofa bed Kitchenette Partial Sea View, Deluxe Studio Suite 1 Queen Bed Kitchenette"
  },
  {
    resortId: "ho211840",
    index: 57,
    resort: "Holland House Beach Hotel",
    website: "https://www.hotels.com/ho211840/holland-house-beach-hotel-philipsburg-sint-maarten/?chkin=2024-11-26&chkout=2024-11-29&x_pwa=1&rfrr=HSR&pwa_ts=1703172675569&referrerUrl=aHR0cHM6Ly93d3cuaG90ZWxzLmNvbS9Ib3RlbC1TZWFyY2g%3D&useRewards=false&rm1=a2&regionId=3331&destination=Philipsburg%2C+Sint+Maarten&destType=CURRENT_LOCATION&selected=1160500&latLong=18.023062%2C-63.044603&sort=RECOMMENDED&top_dp=209&top_cur=USD&userIntent=&selectedRoomType=48335&selectedRatePlan=208300965&expediaPropertyId=1160500&searchId=db9e216a-2d18-42ef-aeda-7f781e74a2ec",
    unitToBeScrabe: "Superior Room City View, Executive Suite City View, Deluxe Room Balcony Beach View, Executive Suit Ocean View, Junior Studio Suite Ocean View"
  }
]

  }),
  created() {
    this.generateYears();
  },
  methods: {
    async submit(){
        // form validation
      if (!this.$refs.form.validate()) {
        await this.$store.dispatch("setLoading", false);
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "error",
          text: "Please check form for errors",
          timeout: 10000,
        });
        return;
      }
      this.loading = true
      this.items.email = this.$store.state.user.email;
      
      await axios.post('https://api.silentauctionvacations.com/getScrapers',this.items)
      .then(res=>{
        this.$toast.success(res.data.message);
        console.log('res',res)
        this.$refs.form.reset();
        this.$refs.form.resetValidation()
      this.loading = false})
      .catch(err=>{
        this.$toast.error(err.response.data.message);
        console.log('err',err)
        this.loading = false
      })
    

    },
    generateYears() {
      const currentYear = new Date().getFullYear();
      for (let year = currentYear; year <= currentYear + 10; year++) {
        this.years.push({ year });
      }
    },
  },

}
</script>